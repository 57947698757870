import { Injectable } from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}
export interface Saperator {
  name: string;
  type?: string;
}
export interface SubChildren {
  state: string;
  name: string;
  type?: string;
}
export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
  child?: SubChildren[];
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  saperator?: Saperator[];
  children?: ChildrenItems[];
}

const MENUITEMS = [
  // {
  //   state: '',
  //   name: 'Gerenciar',
  //   type: 'saperator',
  //   icon: 'gavel',
  // },
  // {
  //   state: 'leiloes',
  //   name: 'Leilões',
  //   type: 'sub',
  //   icon: 'gavel',
  //   children: [
  //     { state: 'todos', name: 'Todos', type: 'link' },
  //     { state: 'ativo', name: 'Ativo', type: 'link' },
  //     { state: 'ao-vivo', name: 'Ao Vivo', type: 'link' },
  //     { state: 'venda-direta', name: 'Venda Direta', type: 'link' },
  //     { state: 'enviar-proposta', name: 'Enviar Proposta', type: 'link' },
  //     { state: 'em-loteamento', name: 'Em Loteamento', type: 'link' },
  //     { state: 'em-breve', name: 'Em Breve', type: 'link' },
  //     { state: 'encerrado', name: 'Encerrado', type: 'link' },
  //     { state: 'suspenso', name: 'Suspenso', type: 'link' },
  //     { state: 'inativo', name: 'Inativo', type: 'link' },
  //   ],
  // },
  // {
  //   state: '',
  //   name: 'Categorias',
  //   type: 'saperator',
  //   icon: 'home',
  // },
  // {
  //   state: 'imoveis',
  //   name: 'Imóveis',
  //   type: 'link',
  //   icon: 'home',
  // },
  // {
  //   state: 'carros',
  //   name: 'Carros',
  //   type: 'link',
  //   icon: 'car',
  // },
  // {
  //   state: 'diversos',
  //   name: 'Diversos',
  //   type: 'link',
  //   icon: 'rocket',
  // },
  {
    state: '',
    name: 'Parceiros / Usuários',
    type: 'saperator',
    icon: 'home',
  },
  // {
  //   state: 'comitentes',
  //   name: 'Comitentes',
  //   type: 'link',
  //   icon: 'users-cog',
  // },
  {
    state: 'leiloeiro/todos',
    name: 'Leiloeiros',
    type: 'link',
    icon: 'user-tie',
  },
  // {
  //   state: 'usuarios',
  //   name: 'Usuários',
  //   type: 'link',
  //   icon: 'users',
  // },
  {
    state: 'sites-falsos',
    name: 'Sites Falsos',
    type: 'link',
    icon: 'shield-alt',
  }
];

@Injectable()
export class MenuItems {
  getMenuitem(): Menu[] {
    return MENUITEMS;
  }
}

import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  CanActivateChild,
} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate{
  constructor(private routes: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const authenticated = localStorage.getItem('Authorization') || sessionStorage.getItem('Authorization');
    
    if (authenticated) {
      return true;
    } else {
      this.routes.navigate(['/authentication/login']);
      return false;
    }
  }
  
}

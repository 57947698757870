import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute, Data } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'fnb-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
  // @Input() layout;
  routeSubscption!: Subscription;
  pageInfo: Data = Object.create(null);
  pageInfoId!: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
            if (route.params) {
              this.routeSubscption = route.params.subscribe((param) => {
                this.pageInfoId = param.id ? param.id : undefined;
              });
            }
          }
          return route;
        })
      )
      .pipe(filter((route) => route.outlet === 'primary'))
      .pipe(mergeMap((route) => route.data))
      .subscribe((event) => {
        this.pageInfo = this.treatIdUrls(event);
        this.titleService.setTitle(this.pageInfo['title']);
      });
  }

  treatIdUrls(routeData: any): Data {
    if (routeData.urls) {
      routeData.urls = routeData.urls.map((url: any) => {
        if (url.isId) {
          if (url.url) {
            url.url = url.url.replace(':id', this.pageInfoId);
          }
          url.title = url.title.replace(':id', this.pageInfoId);
        }
        return url;
      });
    }
    return routeData;
  }

  ngOnInit() {}
  ngOnDestroy(): void {
    this.routeSubscption.unsubscribe;
  }
}

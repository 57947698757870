import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RouterModule } from '@angular/router';
import { MenuItems } from '../layouts/main/sidebar/menu-items/menu-items';
import {
  AccordionAnchorDirective,
  AccordionLinkDirective,
  AccordionDirective
} from './accordion';
import { DatepickerDayMonthHeaderComponent } from './components/datepicker-day-month-header/datepicker-day-month-header.component';
import {SnackBarService} from './services/snack-bar.service'
import { AuthInterceptor } from './services/auth-interceptor'
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {LoadingService} from './services/loading.service'


@NgModule({
  
  declarations: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    DatepickerDayMonthHeaderComponent
  ],
  exports: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    DatepickerDayMonthHeaderComponent
  ],
  imports: [CommonModule, MatIconModule, RouterModule, MatSnackBarModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    MenuItems, 
    SnackBarService,
    LoadingService
  ]
})
export class SharedModule {}

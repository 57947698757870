import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { LoadingService } from './loading.service';
import { catchError, map, switchMap } from 'rxjs/operators'
import { SnackBarService } from './snack-bar.service';
import { AuthenticationService } from '../../authentication/services/authentication.service';
import { CompileNgModuleSummary } from '@angular/compiler';
import * as moment from 'moment';
import { Router } from '@angular/router';



@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;

  constructor(
    private _loading: LoadingService,
    private _snackBar: SnackBarService,
    private _authService: AuthenticationService,
    private _router: Router
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this._loading.setLoading(true, req.url);


    if (!this._authService.getAuthToken()) {

      return next.handle(req)
        .pipe(catchError((err) => {
          this._loading.setLoading(false, req.url);
          this.interceptError(err);
          return err;
        }))
        .pipe(
          map((evt: any) => {
            if (evt instanceof HttpResponse) {
              this._loading.setLoading(false, req.url);
            }
            return evt;
          }));
    }

    const test = req.clone()
    
    const req1 = req.clone({
      headers: !test.headers.has('Authorization') ? req.headers.set('Authorization', `${this._authService.getAuthToken()}`) : test.headers,
    });

    return next.handle(req1)
      .pipe(catchError((err) => {
        if (err instanceof HttpErrorResponse && err.status === 401) {
          this._loading.setLoading(false, req1.url);
          return this.logout()
        }
        this._loading.setLoading(false, req1.url);
        this.interceptError(err);
        return err;
      }))
      .pipe(
        map((evt: any) => {
          if (evt instanceof HttpResponse) {
            this._loading.setLoading(false, req1.url);

            let renewToken = this.validateToken(this._authService.getAuthToken())
            console.log("TOKEN IS VALID", renewToken)

            if (renewToken) {
              if (!this.isRefreshing) {
                this.isRefreshing = true;
                this._authService.refreshToken({ token: this._authService.getAuthToken() }).subscribe((data: any) => {
                  this._authService.setToken(data.body.token)
                }, catchError((err) => {
                  this.interceptError(err)
                  this.logout()
                  return throwError(err);
                }))
              }
            }
          }
          return evt;
        }));

  }


  validateToken(token: string) {
    let decodedToken = this._authService.getDecodedAccessToken(token)
    let now = moment(new Date());
    let end = moment.unix(decodedToken.exp)
    let duration = moment.duration(end.diff(now));
    let hrs = duration.asHours();
    // console.log(end)
    // console.log(hrs)
    console.log("exp token", moment.unix(decodedToken.exp).format("DD/MM/YYYY HH:mm"))
    hrs = 1.9
    return hrs <= 2 ? true : false
  }


  interceptError(err: HttpErrorResponse) {
    this._snackBar.showError(this.getErrorMessage(err), 'OK', 5000, 'top', 'center')
  }

  getErrorMessage({ status, error, message }: HttpErrorResponse) {
    let errorMessage = "";
    if (error && error.detail) {
      errorMessage = error.detail.non_field_errors.map((msg: string) => msg);
    } else {
      errorMessage = message
    }
    return errorMessage
  }


  logout() {
    this._router.navigate(['/authentication/login'])
    sessionStorage.clear()
    localStorage.clear()
    this._snackBar.showError("Sessão expirada, Faça login novamente", 'OK', 5000, 'top', 'center')
  }

}

import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import * as model from '../models/export'
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {

  handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.log("HANDLER ERROR DO SERIVÇO", error)
      return of(result as T);
    };
  }

  constructor(
    private http: HttpClient,
  ) { }

  singIn(params: model.Authentication.SingIn): Observable<any> {
    return this.http
      .post<[any]>(
        `${environment.apiUrl}authentication/obtain_token/`, params, { observe: 'response' })
      .pipe(
        catchError(this.handleError<HttpErrorResponse>('singIn')),
      );
  }

  verifyToken(params: model.Authentication.ValidToken): Observable<any> {
    return this.http
      .post<[any]>(
        `${environment.apiUrl}authentication/verify_token/`, params, { observe: 'response' })
      .pipe(
        catchError(this.handleError<HttpErrorResponse>('verifyToken')),
      );
  }

  refreshToken(params: model.Authentication.ValidToken) {
    params.token = params.token.split(" ")[1]
    return this.http
      .post<[any]>(
        `${environment.apiUrl}authentication/refresh_token/`, params, { observe: 'response' })
      .pipe(
        catchError(this.handleError<HttpErrorResponse>('verifyToken')),
      );
  }

  getAuthToken(): string {
    return localStorage.Authorization || sessionStorage.Authorization;
  }

  setToken(token: string, renember: boolean = false) {
    let tokenDecoded = this.getDecodedAccessToken(token);
    if (renember) {
      localStorage.setItem('Authorization', 'Bearer ' + token)
      localStorage.setItem('userName', tokenDecoded.username.split('.')[0].toUpperCase())
    } else {
      sessionStorage.setItem('Authorization', 'Bearer ' + token)
      sessionStorage.setItem('userName', tokenDecoded.username.split('.')[0].toUpperCase())
    }
  }

  getDecodedAccessToken(token: string): any {
    try {

      return jwt_decode(token);
    }
    catch (Error) {
      return null;
    }
  }

}

import { Component } from '@angular/core';
import { Routes } from '@angular/router';
import { MainComponent } from './layouts/main/main.component';
import { AppBlankComponent } from './layouts/blank/blank.component';
import { AuthenticationComponent } from './authentication/authentication.component'
import { AdminComponent } from './admin/admin.component';
import { HomeComponent } from './web-portal/home/home.component';
import { AuthGuard } from './authentication/authGuard/auth.guard';

export const AppRoutes: Routes = [
    {
        path: '',
        component: HomeComponent,
        children: [
            {
                path: '',
                redirectTo: '/portal',
                pathMatch: 'full'
            },
            {
                path: 'portal',
                component: HomeComponent,
                loadChildren: () => import('./web-portal/web-portal.module').then(m => m.WebPortalModule)
            },
        ]
       
    },
    {
        path: 'admin',
        canActivate: [AuthGuard],
        component: MainComponent,
        children: [
            {
                path: '',
                loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
            },
        ]
    },
    {
        path: '',
        component: AppBlankComponent,
        children: [
            {
                path: 'authentication',
                loadChildren:() => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
            }
        ]
    },
    {
        path: '**',
        redirectTo: 'authentication/404'
    }
 
];

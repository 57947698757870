import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  constructor(
    private snackBar: MatSnackBar
    ) { }

  showSuccess(message: string, action: string, duration: number, verticalPosition: any = 'bottom', horizontalPosition: any = 'center') {
    const config = new MatSnackBarConfig();
    config.panelClass = ['background-green'];
    config.duration = duration;
    config.horizontalPosition = horizontalPosition;
    config.verticalPosition = verticalPosition;
    this.snackBar.open(message, action, config);
  }

  showError(message: string, action: string, duration: number, verticalPosition: any = 'bottom', horizontalPosition: any = 'center') {
    const config = new MatSnackBarConfig();
    config.panelClass = ['background-red'];
    config.duration = duration;
    config.horizontalPosition = horizontalPosition;
    config.verticalPosition = verticalPosition;
    this.snackBar.open(message, action, config);
  }

}

<div class="page-construct-background">
  <img
    alt="Inteligência, automatização de processos e leilões. Em breve."
    class="page-construct"
  />
  <!-- <div class="d-flex justify-content-center fixed-bottom social-medias">
    <button type="button" class="btn btn-secondary btn-circle-lg btn-circle social-media-btn"><i
      class="fa fa-facebook"></i> </button>
    <button type="button" class="btn btn-primary btn-circle-lg btn-circle social-media-btn"><i
          class="fa fa-instagram"></i> </button>
    <button type="button" class="btn btn-success btn-circle-lg btn-circle social-media-btn"><i
          class="fa fa-linkedin"></i> </button>
    <button type="button" class="btn btn-info btn-circle-lg btn-circle social-media-btn">
      <i class="fa fa-twitter"></i>
    </button>
    <button type="button" class="btn btn-warning btn-circle-lg btn-circle social-media-btn"><i
          class="fa fa-whatsapp"></i> </button>
  </div> -->
</div>
